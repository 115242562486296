<template>
  <div class="">
    <sgv-table
      :rKey="rKey"
      :items="logs"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">

      <template slot-scope="{item, hidden}">
        <tr class="">
          <td
            v-if="hidden.type">
            {{item.type}} ({{item.level}})
            <div class="text-primary">
              <small>{{item.tag}}</small>
            </div>
          </td>
          <td v-if="hidden.createdAt">
            {{item.createdAt | datetime}}
          </td>
          <td
            v-if="hidden.name"
            :class="getNameClass(item.level)">
            {{item.name}}
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_LOG } from '../graph/log'

export default {
  mixins: [retainMixin],
  props: {
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    deviceId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      rKey: `Device${this.$form.capitalize(this.deviceType)}DetailLog`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'ประเภท', value: 'type'},
        {text: 'วันที่', value: 'createdAt'},
        {text: 'รายละเอียด', value: 'name', filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['type', 'createdAt', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      logs: []
    }
  },
  apollo: {
    logs: {
      query () {
        return LIST_LOG(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          q: this.getFilter(this.filter)
        }
      },
      fetchPolicy: 'no-cache',
      pollInterval: 5000
    }
  },
  methods: {
    getNameClass (level) {
      if (level === 'M') return ['text-warning']
      else if (level === 'H') return ['text-danger']
    },
    getFilter (v) {
      return {
        ...v,
        params: v.params || {}
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
